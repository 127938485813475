<template>
  <query-table
    :columnsFormatData="columnsFormatData"
    :askData="askData"
    :refreshData="update_count"
    uri_name="/auth_interface"
    main_width="1280"
  ></query-table>
</template>
<script>
import queryTable from "@/components/Common/QueryTable.vue";
//import raListExpand from "./RaListExpand.vue";


export default {
  name: "ruleList",
  props: [],
  components: {
    queryTable
  },
  data() {
    return {
      op_name: this.$options.name,
      askData: "ruleList",
      update_count: 0,
      columnsFormatData: [
        /*{
            type: 'expand',
            width: 30,
            render: (h, params) => {
                return h(riskListExpand, {
                    props: {
                        row: params.row
                    }
                })
            }
        },*/
        {
          title: "名称",
          key: "name",
          align: "center",
        },
        {
          title: "类别",
          key: "rrank",
          width: 150,
          align: "center"
        },
        {
          title: "发布时间",
          key: "pubdate",
          sortable:true,
          width: 150,
          align: "center"
        },
        {
          title: "执行时间",
          key: "exedate",
          sortable:true,
          align: "center",
          width: 150
        },
        {
          title: "状态",
          key: "stat",
          width: 120,
          align: "center"
        },
        {
          title: "详情",
          key: "des",
          align: "center",
          width: 130,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small"
                  },
                  style: {},
                  on: {
                    click: () => {
                      this.linkTo(params.row.file_url);
                    }
                  }
                },
                "查看"
              )
            ]);
          }
        }
      ]
    };
  },
  methods: {
    goNav() {
      location.href = "/risk_nav";
    },
    linkTo(url) {
      window.open(this.$static_url+url+'?userToken='+localStorage.getItem("current_user_token"));
    }
  }
};
</script>
<style scoped>
</style>